// src/pages/Contact.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    // Reset form after submission
    setFormData({ name: '', email: '', message: '' });
  };

  return (
    <ContactContainer>
      <SectionHeader>Contact Us</SectionHeader>
      <Paragraph>If you have any questions or would like to reach out to us, please fill in the form below:</Paragraph>
      <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          name="name"
          placeholder="Your Name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <Input
          type="email"
          name="email"
          placeholder="Your Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <Textarea
          name="message"
          placeholder="Your Message"
          value={formData.message}
          onChange={handleChange}
          required
        />
        <SubmitButton type="submit">Send Message</SubmitButton>
      </Form>

      {/* Back to Home Button */}
      <BackToHome to="/">Back to Home</BackToHome>
    </ContactContainer>
  );
};

export default Contact;

// Styled Components
const ContactContainer = styled.div`
  padding: 80px 20px;
  text-align: center;
`;

const SectionHeader = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #333;
`;

const Paragraph = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2em;
  color: #666;
  margin-bottom: 40px;
`;

const Form = styled.form`
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Input = styled.input`
  padding: 15px;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 1.1rem;
  font-family: 'Poppins', sans-serif;

  &:focus {
    outline: none;
    border-color: #4CAF50;
  }
`;

const Textarea = styled.textarea`
  padding: 15px;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 1.1rem;
  font-family: 'Poppins', sans-serif;
  height: 150px;

  &:focus {
    outline: none;
    border-color: #4CAF50;
  }
`;

const SubmitButton = styled.button`
  padding: 15px 20px;
  border: none;
  background-color: #4CAF50;
  color: white;
  font-size: 1.2rem;
  font-family: 'Poppins', sans-serif;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #45a049;
  }
`;

// Back to Home Button Styled
const BackToHome = styled(Link)`
  display: inline-block;
  margin-top: 20px;
  padding: 12px 25px;
  background-color: #333;
  color: white;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #555;
  }
`;
