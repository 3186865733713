// src/components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Header = () => {
  return (
    <HeaderContainer>
      <HeaderText>Arpeggio AI</HeaderText>
      <Nav>
        <NavButton to="/">Home</NavButton>
        <NavButton to="/#about">About</NavButton>
        <NavButton to="/contact">Contact</NavButton>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;

// Styled components for the Header
const HeaderContainer = styled.header`
  background: linear-gradient(90deg, #4CAF50, #2ECC71);
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const HeaderText = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
  color: white;
`;

const Nav = styled.nav`
  display: flex;
  gap: 20px;
`;

const NavButton = styled(Link)`
  text-decoration: none;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  padding: 10px 20px;
  background-color: #2ECC71;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #27ae60;
  }
`;
