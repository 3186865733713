import React from 'react';
import styled from 'styled-components';
import Header from '../components/Header';

const HomePage = () => {
  return (
    <Container>
      <Header />
      <Content>
        <BackgroundImage />
        <Overlay>
          {/* Section: Who We Are */}
          <Section>
            <SectionHeader>Who We Are</SectionHeader>
            <Paragraph>
              We are two UC Berkeley alums passionate about the intersection of AI and music.
              Our mission is to merge cutting-edge technology with musical creativity, making learning more intuitive and accessible.
            </Paragraph>
          </Section>

          {/* Section: What We Do */}
          <Section>
            <SectionHeader>What We Do</SectionHeader>
            <Paragraph>
              We are building a platform that provides real-time next-best-bar recommendations, guiding musicians through their compositions.
              Powered by an expert model trained on music theory, the platform explains not just what to play next, but why, offering deep insights into the musical structure.
            </Paragraph>
          </Section>

          {/* Section: Why We Do It */}
          <Section>
            <SectionHeader>Why We Do It</SectionHeader>
            <Paragraph>
              Our goal is to lower the cost of music education and empower musicians to understand their craft better. By making personalized, expert-level guidance available to all,
              we aim to make music learning easier, more affordable, and accessible to a broader audience.
            </Paragraph>
          </Section>

        </Overlay>
      </Content>
    </Container>
  );
};

export default HomePage;

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;  /* Ensures content fits and grows as needed */
`;

const Content = styled.div`
  flex: 1;
  position: relative;
  padding-top: 80px;  /* Ensures space below the header */
  padding-bottom: 80px;  /* Prevent content from touching the bottom */
  overflow-y: auto;  /* Allows scrolling if the content grows beyond the viewport */
`;

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/background.jpg');
  background-size: cover;
  background-position: center;
  filter: blur(3px) brightness(0.5); /* Blurs and darkens the background */
  z-index: -1; /* Puts it behind all other content */
`;

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.3);  /* Adding transparency to darken the background */
  padding: 30px;
  border-radius: 15px;
  color: white;
  text-align: center;
  max-width: 800px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin: 0 auto;
  animation: fadeIn 1s ease-in-out;
`;

const Section = styled.div`
  margin-bottom: 50px;
  padding: 20px 0;
`;

const SectionHeader = styled.h1`
  font-size: 2.5em;
  font-family: 'Poppins', sans-serif;
  color: #f0f0f0;
  text-shadow: 1px 2px 5px rgba(0, 0, 0, 0.4);
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  font-size: 1.2em;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.8;
  margin: 0 auto;
  color: #dcdcdc;
  max-width: 700px;  /* Limit paragraph width */
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
  padding: 0 20px; /* Padding to give it room on mobile */
`;
